<template>
  <div>
    <validation-observer ref="formRules">
      <!-- head -->
      <div>
        <b-card>
          <b-card-header class="nopadding">
            <b-row
              no-gutters
              class="w-100"
            >
              <b-col md="12">
                <div class="d-flex justify-content-between">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-secondary"
                    class="btn-icon rounded-circle"
                    @click="goBack()"
                  >
                    <i class="las la-arrow-left" />
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-popover.hover.focus="'Détails.'"
                    size="lg"
                    variant="outline-primary"
                    class="btn-icon rounded-circle"
                    title="Information"
                  >
                    <feather-icon icon="BellIcon" />
                  </b-button>
                </div>
              </b-col>
              <b-col
                md="12"
                class="d-flex align-items-center justify-content-center"
              >
                <div>
                  <h1 class="m-auto text-center">
                    Nouvelle délibération des demandes
                  </h1>
                  <br>
                  <!-- <h4 class="m-au to text-center">
                    Hôtels et établissements assimilés
                  </h4> -->
                </div>
              </b-col>
            </b-row>
          </b-card-header>
          <content-loader
            v-if="loading"
            view-box="0 0 400 460"
            :speed="2"
            primary-color="#f3f3f3"
            secondary-color="#ecebeb"
          >
            <circle
              cx="29"
              cy="30"
              r="17"
            />
            <rect
              x="58"
              y="18"
              rx="2"
              ry="2"
              width="140"
              height="10"
            />
            <rect
              x="58"
              y="34"
              rx="2"
              ry="2"
              width="140"
              height="10"
            />
            <rect
              x="-7"
              y="60"
              rx="2"
              ry="2"
              width="408"
              height="132"
            /></content-loader>
        </b-card>
      </div>
      <b-row v-if="!loading">
        <!-- navigation -->
        <!-- <b-col
          lg="2"
          class="d-none d-lg-block"
        >
          <div>
            <b-card>
              <b-list-group>
                <b-list-group-item href="#renseignementsgeneraux">
                  RENSEIGNEMENTS GÉNÉRAUX
                </b-list-group-item>
                <b-list-group-item href="#pv">
                  PROCÈS VERBAL
                </b-list-group-item>
                <b-list-group-item href="#arrete">
                  ARRÊTÉ
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </div>
          <div class="text-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              block
              @click="save_order()"
            >
              <b-spinner
                v-if="loadingAddArrete"
                small
              />
              Enregistrer
            </b-button>
          </div>
        </b-col> -->
        <!-- body -->
        <b-col
          lg="12"
          class="pb-2"
        >
          <!-- RENSEIGNEMENTS GENERAUX  -->
          <div>
            <b-card id="renseignementsgeneraux">
              <!-- <b-card-header class="d-flex justify-content-between">
                <h3>RENSEIGNEMENTS GÉNÉRAUX</h3>
              </b-card-header>
              <hr> -->
              <!-- <br> {{ new_arrete }} -->
              <b-card-body class="row">
                <span class="h3 col-12 mb-1"> Informations de bases </span>
                <!-- predicted_date -->
                <div class="col-6">
                  <b-form-group
                    class="col pr-0 pl-0"
                  >
                    <span class="d-flex justify-content-between">
                      <label for="new_request">{{ $t('bylaws_add.label_predicted_date') }}</label>
                    </span>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|beforeOrToday"
                    >
                      <b-input-group>
                        <b-form-datepicker
                          v-model="new_arrete.predicted_date"
                          class=""
                          placeholder="Aucune date sélectionnée"
                          :min="today"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <!-- meeting date -->
                <div class="col-6">
                  <b-form-group
                    class="col pr-0 pl-0"
                  >
                    <span class="d-flex justify-content-between">
                      <label for="new_request">{{ $t('bylaws_add.label_meeting_date') }}</label>
                    </span>
                    <b-input-group>
                      <b-form-datepicker
                        v-model="new_arrete.meeting_date"
                        class=""
                        placeholder="Aucune date sélectionnée"
                        :min="today"
                      />
                    </b-input-group>
                  </b-form-group>
                </div>

                <!-- participants interns -->
                <div class="col-12">
                  <b-form-group
                    class="col pr-0 pl-0"
                  >
                    <span class="d-flex justify-content-between">
                      <label for="new_request">{{ $t('bylaws_add.label_participants_interns') }}</label>
                    </span>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|beforeOrToday"
                    >
                      <b-input-group>
                        <v-select
                          :disabled="list_participants.length == 0"
                          v-model="new_arrete.participants_ids"
                          label="label"
                          :reduce="(option) => option.id"
                          :options="list_participants"
                          placeholder="Choisissez un / des utilisateur(s)"
                          multiple
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                
                <!-- participants externs -->
                <div class="col-12">
                  <b-form-group
                    class="col pr-0 pl-0"
                  >
                    <span class="d-flex justify-content-between">
                      <label for="new_request">{{ $t('bylaws_add.label_participants_externs') }}</label>
                    </span>
                    <b-input-group>
                      <b-form-tags
                        v-model="new_arrete.participants_string"
                        input-id="tags-separators"
                        separator=",;"
                        placeholder="Entrez des Noms et prénoms separés par des virgules , ou des points virgules ; "
                        no-add-on-enter
                        class="mb-2"
                      />
                    </b-input-group>
                  </b-form-group>
                </div>
                
                <hr class="hr-primary">

                <span class="h3 col-12 mb-1"> Demandes associées </span>
                <!-- filter request -->
                <!-- <div class="col-12 "> -->
                  <!-- secteur_id -->
                  <b-col lg="6">
                    <b-form-group
                      :label="
                        $t(
                          'customer_information.information_content.create_input_sector'
                        )
                      "
                      label-cols-md="12"
                      label-for="mots-cles"
                    >
                      <v-select
                        id="roles"
                        v-model="filterBy.secteur_id"
                        :placeholder="
                          $t(
                            'customer_information.information_content.create_input_sector_placeholder'
                          )
                        "
                        :dir="
                          $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                        "
                        label="title"
                        :reduce="(option) => option.id"
                        :options="listSecteurs"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- type_demande_id -->
                  <b-col lg="6">
                    <b-form-group
                      :label="'Types de demandes'"
                      label-cols-md="12"
                      label-for="mots-cles"
                    >
                      <v-select
                        v-model="filterBy.type_demande_id"
                        :placeholder="'Choisissez un type de demande'"
                        :dir="
                          $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                        "
                        label="title"
                        :reduce="(option) => option.id"
                        :options="listTypesDemandes"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- periode -->
                  <b-col lg="6">
                    <b-form-group
                      label-cols-md="12"
                      :label="$t('request_manage.filter_label_period')"
                    >
                      <b-input-group>
                        <DateRangeWidget
                          v-model="filterBy.periode"
                          :range="filterBy.periode"
                          placeholder="Filtrer par date"
                          class="p-0 m-0"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <!-- statuses -->
                  <!-- <b-col lg="6">
                    <b-form-group
                      :label="'Etat de la demande'"
                      label-cols-md="12"
                      label-for="mots-cles"
                    >
                      <v-select
                        v-model="filterBy.statuses"
                        multiple
                        :placeholder="''"
                        :dir="
                          $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                        "
                        label="etape"
                        :reduce="(option) => option.status"
                        :options="statuses"
                      />
                    </b-form-group>
                  </b-col> -->
                  <!-- reference -->
                  <b-col lg="6">
                    <b-form-group
                      label="Rechercher par reference"
                      label-cols-md="12"
                    >
                      <b-input-group>
                        <b-form-input
                          v-model="filterBy.reference"
                          type="text"
                          class="form-control"
                          placeholder="Entrer la référence .Exemple: 0VZDBVX2dk59uDDoQ594 "
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col lg="12">
                    <div class="d-flex flex-row-reverse nopadding">
                      <b-button
                        class="float-right ml-2"
                        variant="warning"
                        @click="load_requests()"
                      >
                        <feather-icon
                          icon="SearchIcon"
                          class="mr-25"
                        />
                        <span>Rechercher les demandes</span>
                        <b-spinner v-if="isLoading" small class="ml-1"/>
                      </b-button>

                      <b-button
                        class="float-right"
                        variant="secondary"
                        @click="reset_filter()"
                      >
                        <feather-icon
                          icon="TrashIcon"
                          class="mr-25"
                        />
                        
                        <span>Réinitialiser le filtre</span>
                      </b-button>
                      
                    </div>
                  </b-col>
                <!-- </div> -->

                <!-- add requests -->
                <div class="col-12 mt-2">
                  <div class="d-flex flex-row-reverse nopadding">
                    <b-form-group
                      class="col pr-0 pl-0"
                    >
                      <span class="d-flex justify-content-between">
                        <label for="new_request"> Demandes traitées à associer : </label>
                        <span
                          v-if="requests_table_data.length !== 0 && requests_table_data.length !== new_arrete.demandes.length"
                          v-b-tooltip.hover.right="'Sélectionner toutes les demandes en attente de decision de l\'Organe Technique'"
                          @click="select_all_requests_in_waiting()"
                        >
                          <i class="las la-check-double iconMedium" />
                        </span>
                      </span>
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                      >
                      <!-- requests_table_data.length == {{requests_table_data.length}} = {{ requests_table_data.length == 0 }} -->
                        <b-input-group>
                          <v-select
                            id="new_request"
                            v-model="new_arrete.demandes"
                            v-b-tooltip.hover.bottom="
                              (requests_table_data.length == 0 && 'Aucune demande en attente de décision de l\'organe technique')
                            "
                            :disabled="requests_table_data.length === 0"
                            multiple
                            :placeholder="''"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="label"
                            :reduce="(option) => option"
                            :options="requests_table_data"
                            class="select-size-lg"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </div>

          <!-- PV  -->
          <!-- <div>
            <b-card id="renseignementsgeneraux">
              <b-card-header class="d-flex justify-content-between">
                <h3>PROCÈS VERBAL</h3>
              </b-card-header>
              <hr>
              <b-card-body />
            </b-card>
          </div> -->

          <!-- ARRETE  -->
          <!-- <div>
            <b-card id="renseignementsgeneraux">
              <b-card-header class="d-flex justify-content-between">
                <h3>ARRÊTÉ</h3>
              </b-card-header>
              <hr>
              <b-card-body />
            </b-card>
          </div> -->

          <div class="d-flex justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="col-xl-2"
              @click="save_order()"
            >
              <b-spinner
                v-if="loadingAddArrete"
                small
              />
              Enregistrer
            </b-button>
          </div>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BCardImg,
  BBadge,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroup,
  VBPopover,
  BModal,
  BSpinner,
  VBTooltip,
  BFormDatepicker,
  BFormTags
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions } from 'vuex'
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import { $themeColors } from '@themeConfig'
import vSelect from 'vue-select'
import TipsModalCreateFromLastRecord from '@/components/TipsModalCreateFromLastRecord.vue'
import DateRangeWidget from '@/components/DateRangeWidget.vue'
import PaginationComponent from '@/components/PaginationComponent.vue'
import secteursStoreModule from '@/store/secteurs'
import typeDemamdesStoreModule from '@/store/type-demandes'
import statusStoreModule from '@/store/status'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import statuses from '@/views/apps/formulaires/requests_states.js'

import utilsService from '@/services/utils/utils.service'

import formulairesStoreModule from '@/store/formulaire'
import etablissementsStroreModule from '@/store/etablissements'
import usersStoreModule from '@/store/users'
import arretesStoreModule from '@/store/arretes'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

import { buildFiltersForRequest } from '@/helpers/http-utils'
import demandes from '@/store/demandes'

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BCardImg,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BBadge,
    BSpinner,
    VBPopover,
    BModal,
    BFormTags,

    AppCollapse,
    AppCollapseItem,

    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
    TipsModalCreateFromLastRecord,
    DateRangeWidget,
    PaginationComponent,
    ToastificationContent,
    vSelect,
    BFormDatepicker,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
    Ripple,
  },
  setup() {
    const requiredStoreModules = [
      { path: 'formulaires', module: formulairesStoreModule },
      { path: 'status', module: statusStoreModule },
      { path: 'secteurs', module: secteursStoreModule },
      { path: 'type-demandes', module: typeDemamdesStoreModule },
      { path: 'etablissements', module: etablissementsStroreModule },
      { path: 'users', module: usersStoreModule },
      { path: 'arretes', module: arretesStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)
    const { isEtablissementUser, isAdminEtablissement, isAgentEtablissement } = utilsService.currentUserUtils()

    const departementsList = utilsService.getDepartements()
    const communesList = utilsService.getCommunes()

    return {
      departementsList,
      communesList,
      isAdminEtablissement,
      isAgentEtablissement,
      isEtablissementUser,
      requiredStoreModules,
    }
  },
  props: {
    slug: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      etablissementsList: [],
      loadingAddArrete: false,

      loading: false,
      statsLoad: null,

      lastestRecordedForm: null,
      new_arrete: {
        // reference: null,
        // numero: null,
        // validation_ministre: null,
        // visa_ministre: null,
        // status: null,
        demandes: [],
        meeting_date: moment().format('YYYY-MM-DD'),
        predicted_date: moment().format('YYYY-MM-DD'),
        participants_ids: [],
        participants_string: [],
      },
      list_participants: [],
      requests_next_statuses: [],
      status_value : { 
        deliberation_session_check: [
          'technical_control_check', 
          'go_to_ot_deliberation', 
          'interview_with_the_applicant', 
          'submit_investigations_repport',
          'check_meet_with_applicant',
          'validation_completion_of_the_procedure'
        ],
        classification_deliberation_session_check: [
          'audit_repport_check',
          'do_not_make_mystery_audit'
        ],
      },
      statuses,
      requests_table_data: [],
      selectedForm: null,
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 15,
        metaData: { from: 0, to: 0 },
      },
      isLoading: false,

      months: utilsService.getListMonths(),
      years: utilsService.getListYears(),

      optionsDemandes: null,
      selectedDemandes: null,
      today: moment().format('YYYY-MM-DD'),
      filterBy: {
        secteur_id: null,
        type_demande_id: null,
        identity: null,
        periode: null,
        reference: null,
        // statuses: [], // "on_hold"
      },
      listSecteurs: [],
      isFetchingSecteur: false,
      listTypesDemandes: [],
      isFetchingTypeDemande: false,
      listMetiers: [],
      isFetchingRequests: false,
    }
  },

  computed: {},

  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        this.load_requests()
        // -----------------------
        // load technic agents ------------------------------------------------------------
        const params = {
          roles: ['UAT', 'AUAT', 'TA', 'TAD', 'AUD'],
        }
        this.action_searchUsers(params)
          .then(async (response) => {
            if (response.status === 200) {
              this.list_participants = response.data.data
              this.list_participants.forEach((auditeur) => {
                auditeur.label = `${auditeur.lastname} ${auditeur.firstname}, ${auditeur.email}`
              })
              // console.log('this.list_participants::: ', this.list_participants);
            }
          })
          .catch((err) => { console.log('err::: ', err) })
      },
    },
    'new_arrete.demandes': {
      immediate: false,
      deep: true,
      handler(val, old) {
        this.get_next_status()
      },
    },
    // 'paginationData.currentPage': {
    //   handler(val, old) {
    //     this.getListArretes()
    //   },
    // },
    // 'paginationData.perPage': {
    //   handler(val, old) {
    //     this.getListArretes()
    //   },
    // },
  },
  beforeDestroy() {
    // unregisterStoreModule(this.requiredStoreModules);
  },

  mounted() {
    this.fetchSecteurs()
    this.fetchTypesDemandes()
  },

  methods: {
    ...mapActions('formulaires', {
      action_searchFormulaires: 'searchFormulaires',
    }),
    ...mapActions('arretes', {
      action_fetchArretes: 'fetchArretes',
      actions_searchArretes: 'searchArretes',
      actions_addArrete: 'addArrete',
    }),
    ...mapActions('users', {
      action_searchUsers: 'searchUsers',
    }),
    ...mapActions('status', {
      action_fetchStatus: 'fetchStatus',
    }),
    ...mapActions('type-demandes', {
      action_fetchTypesDemandes: 'fetchTypeDemandes',
    }),
    ...mapActions('secteurs', {
      action_fetchSecteurs: 'fetchSecteurs',
    }),
    // ------------------------------------------------------------------------------------------------
    save_order() {
      // const { currentUserId, etablissementDetails } = utilsService.currentUserUtils()
      // const { sousTypeEtablissement } = etablissementDetails;
      this.$refs.formRules.validate().then(async success => {
        if (success) {
          const date_reversed_meeting_date = this.reverseDate(this.new_arrete.meeting_date, '-')
          const date_reversed_predicted_date = this.reverseDate(this.new_arrete.predicted_date, '-')
          let requests = []
          this.new_arrete.demandes.map((req, req_index) => {
            req.id
            requests.push({
              demande_id : req.id,
              next_status : this.requests_next_statuses[req_index]
            })
          }),

          this.loadingAddArrete = true
          const data = {
            // ...this.new_arrete,
            status: "deliberation_session_check",
            demandes: requests,
            meeting_date: date_reversed_meeting_date,
            predicted_date: date_reversed_predicted_date,
            no_sync_with_requests: true,
            participants_ids: this.new_arrete.participants_ids,
            participants_string: this.new_arrete.participants_string,
          }
          console.log('actions_addArrete data::: ⭕️', data);
          // return
          this.actions_addArrete(data)
            .then(res => {
              this.loadingAddArrete = false
              if (res.status === 201) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Arrêté créé avec succès',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                this.$router.push({
                  name: 'arretes-list',
                })
              }
            })
            .catch(err => {
              console.log(err)
              this.loadingAddArrete = false
            })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Des champs sont requis.',
              icon: 'AlertTriangleIcon',
              variant: 'warning',
            },
          })
        }
      })
    },
    load_requests() {
      this.isLoading = true
      const params = {
        ...{
          ...this.filterBy,
          statuses: [
            'technical_control_check', 
            'go_to_ot_deliberation', 
            'interview_with_the_applicant', 
            'submit_investigations_repport',
            'check_meet_with_applicant',
            'validation_completion_of_the_procedure',
            'audit_repport_check',
            'do_not_make_mystery_audit',
            'dont_make_technical_control',
            'in_process_technical_control',
            'classification_repport_check',
            'reclassification_repport_check',
            'in_process_technical_control'
          ],
          hasnt_arrete: true,
        },
        page: 1,
        perPage: 1000000000,
      }
      // console.log('⭕️load_requests params::: ', params);
      this.action_searchFormulaires(params)
        .then(res => {
          // console.log('⭕️res::: ', res.data.data);
          if (res.status === 200) {
            this.isLoading = false
            this.requests_table_data = []
            res.data.data.forEach(item => {
              console.log('⭕️⭕️⭕️ item::: ', item);
              const slug = utilsService.getSlugByCodeDemande(item.type_demande.code)
              const selectedForm = utilsService.getFormulaireByCodeClient(slug)
              let label = null
              switch (selectedForm.sector) {
                case 'UEH':
                  label = `Référence demande: ${item.reference} --- Établissement: ${item.user?.profile.nom_etablissement} de ${item.user?.firstname} ${item.user?.lastname} --- Demande: ${item.type_demande.title}`
                  break;
                case 'UEAV':
                  label = `Référence demande: ${item.reference} --- Agence: ${item.user?.profile.nom_etablissement} de ${item.user?.firstname} ${item.user?.lastname} --- Demande: ${item.type_demande.title}`
                  break;
                case 'UEGT':
                  label = `Référence demande: ${item.reference} --- Guide: ${item.user?.firstname} ${item.user.lastname} --- Demande: ${item.type_demande.title}`
                  break;
                case 'UER':
                  label = `Référence demande: ${item.reference} --- Établissement: ${item.user?.profile.nom_etablissement} de ${item.user?.firstname} ${item.user?.lastname} --- Demande: ${item.type_demande.title}`
                  break;
                default:
                  break;
              }

              let build_object = {
                ...item.data,
                selectedForm,
                slug,
                user: item.user,
                user_id: item.user_id,
                id: item.id,
                statuses: item.statuses,
                created_at: item.created_at,
                updated_at: item.updated_at,
                duration: item.type_demande.duration,
                type_demande: item.type_demande,
                auto_evaluation: item.auto_evaluation,
                audit: item.audit,
                reference: item.reference,
                code_demande: item.type_demande.code,
                label, 
                user: item.user,
                
              }

              this.requests_table_data.push(build_object)
            })
            // console.log('this.requests_table_data::: 🟢⭕️🟢', this.requests_table_data);
            // --------------------------------------------------------------
            this.paginationData.total = res.data.meta.total || 0
            if (this.paginationData.metaData.from === 0) {
              this.paginationData.metaData.from += 1
            } else {
              this.paginationData.metaData.from = this.paginationData.perPage * this.paginationData.currentPage
                - this.paginationData.perPage
            }
            this.paginationData.metaData.to = this.paginationData.perPage * this.paginationData.currentPage
              - this.paginationData.perPage
              + res.data.meta.total
            // --------------------------------------------------------------
          }
        })
        .catch(err => {
          console.log(err)
          this.isLoading = false
        })
    },
    reset_filter(){
      this.filterBy = {
        secteur_id: null,
        type_demande_id: null,
        identity: null,
        periode: null,
        reference: null,
        // statuses: [], // "on_hold"
      },
      this.load_requests()
    },
    fetchSecteurs() {
      this.isFetchingSecteur = true
      this.action_fetchSecteurs()
        .then(response => {
          this.isFetchingSecteur = false
          this.listSecteurs = response.data.data
        })
        .catch(error => {
          console.log('error::: ', error);
          this.isFetchingSecteur = false
        })
    },
    fetchTypesDemandes() {
      this.isFetchingTypeDemande = true
      this.action_fetchTypesDemandes()
        .then(response => {
          this.isFetchingTypeDemande = false
          this.listTypesDemandes = response.data.data
        })
        .catch(error => {
          this.isFetchingTypeDemande = false
        })
    },
    async loadMetiers() {
      try {
        const response = await this.action_getListTypesEtablissement()
        if (response.status === 200) {
          this.listMetiers = response.data.data
        }
      } catch (error) {
        console.log('error::: ', error);
      }
    },
    select_all_requests_in_waiting() {
      this.new_arrete.demandes = this.requests_table_data
      this.get_next_status()
    },
    get_next_status(){
      this.requests_next_statuses = []
      for (let index = 0; index < this.new_arrete.demandes.length; index++) {
        const request = this.new_arrete.demandes[index];
        const last_status = request.statuses[0].name
        console.log('last_status::: ', last_status);

        if( !request.selectedForm.have_many_requests_in_one ){
          console.log('::: in many in one', );
          this.requests_next_statuses.push('deliberation_session_check')
        } else {
          if(this.status_value.deliberation_session_check.includes(last_status)){
            console.log('::: classic process', );
            this.new_arrete.demandes[index].next_status = 'deliberation_session_check' 
            this.requests_next_statuses.push('deliberation_session_check')
          }
          if(this.status_value.classification_deliberation_session_check.includes(last_status)){
            console.log('::: classification', );
            this.new_arrete.demandes[index].next_status = 'classification_deliberation_session_check' 
            this.requests_next_statuses.push('classification_deliberation_session_check')
          }
        }
        console.log('::: ', this.requests_next_statuses);
      }
    },
    onPaginationChange(val) {
      this.paginationData = { ...this.paginationData, ...val }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "@core/scss/base/pages/app-invoice.scss";

.per-page-selector {
  width: 90px;
}

.main-grid {
  display: grid;
  grid-template-columns: auto 400px;
  column-gap: 30px;
  position: relative;

  .options-sidebar {
    background: #fff;
    overflow: auto;
    height: 80vh;
    padding-bottom: 59px;
    position: fixed;
    right: 1.5rem;
    width: 400px;
  }
}

.v-select {
  width: 100%;
}

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {
  .main-grid {
    display: unset;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    row-gap: 30px;
    position: relative;

    .options-sidebar {
      overflow: auto;
      height: unset;
      position: unset;
      width: unset;
    }
  }
}

.d-content {
  display: contents;
}

#sidebar-wrapper {
  position: fixed;
  right: 250px;
  width: 100%;
  height: 100%;
  margin-right: -90%;
  overflow-y: auto;

  .sidecard {
    margin: 0 0 800px 0;
  }
}

@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }

  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
